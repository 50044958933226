:root {
  --color-surface: #fff;
  --color-solid: black;
  --color-primary: #368260; /*#f3b93a*/
  --color-secondary: teal;
  --color-sfondo: #f5f5f5;
}

body{
  background: var(--color-sfondo)
}

.App {
  text-align: center;
}

.colorp{
  fill:var(--color-primary)
}

a:focus,
button:focus,
input:focus,
textarea:focus {
outline: none;
box-shadow: 0 0 0 1px #ffffff00, 0 0 0 3px rgba(19, 124, 189, 0), inset 0 1px 1px rgba(16, 22, 26, 0)
}
a{
  text-decoration: none;
  color: black
}
.cartCloseMob{
  display: none;
}
.conterC{
  margin-left: 10px;
background: white;
padding: 9px;
border-radius: 40px;
padding-left: 12px;
padding-right: 12px;
color: var(--color-primary);
}
h3{
  margin: 0;
}
.tagSconto{
  position: absolute;
    right: 0px;
    padding: 5px;
    background: orange;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-weight: 800;
}

.barra{
  min-height: 50px;
  padding-bottom: 20px
}
.container{

}
.containerFix{
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-surface);
  border-radius: 10px
}
.modalType{
  width: 500px
}
.grid02{
  display: grid;
  grid-template-columns: 600px auto;
  height: 451px;
}
.grid03{
  display: grid;
  grid-template-columns: 60% 30%
}
.gridSin{
  display: grid;
  grid-template-columns: auto auto;
  padding: 30px;
}
.gridPiatti{
  margin: 20px auto 100px;
    display: grid;
    grid-template-columns: auto 380px;
    max-width: 1120px;
    position: relative;

}
.info{
  padding: 20px;
  border-radius: 3px
}
.restaurant{
  margin-top: 20px;
  position: relative;
  z-index: 1;

}
.specialBar{
  height: 300px;
  position: absolute;
  width: 100%;
  background: var(--color-primary);
}
.barraCategorie nav{
  flex-wrap: wrap;
  overflow-x: scroll;
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
}
.categoriaDiv{
  padding: 20px;
  font-size: 16px
}
.cassaMobile{
    display: none
}
.categoriaDiv button{
  font-size: 15px;
  text-transform: capitalize;
  color: var(--color-primary);
  font-weight: 400;
}

.cassa{
  flex: 0 0 33%;
  background: #fff;
  display: flex;
  padding: 10px;
  position: absolute;
  right: 0px;
  width: 380px;
  z-index: 1;
}
.boxlist{
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 20px
}
.f1{
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
      overflow: hidden;
}
.mobileC{
  display: none;
}
.DescckC{
  display: block;
}
.cardList{
  background: var(--color-surface);
  cursor: pointer;
}
.buttonAsk {
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    display: table;
}
.buttonAsk:hover{
  background: black;
  color: white
}
.buttonActionBar{
    background: white;
    display: inline-table;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 4px;
    margin-right: 10px
}
.vaiCassa{
  color: white;
  font-weight: 800;
  cursor: pointer;
  background-color: var(--color-primary);
  margin-bottom: 20px;
}
.footr{
  height: 300px;
  width: 100%;
  background:#464646
}
.inputD{
  display: flex;
  width: 100%
}
.inputD div{
  width: 100%;
  padding: 10px
}
.inputD label{

}

.inputD input{
  margin-top: 7px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  width: -webkit-fill-available;
}
.barron{
  border-width: 0 0 1px 0;
    margin: 0 16px;
    border-color: rgba(0,0,0,0.08);
}
.iconSocial{
  width: 20px;
  margin-right:10px
}
.buttonAction{
  border: none;
  padding: 15px;
  font-size: 20px;
  background: var(--color-primary);
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  width: 96%;
  font-weight: bold;
  cursor: pointer;
}
.buttonAction:hover {
  opacity: 0.8
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {



}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {



}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {



}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {



}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {



}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .grid03{
    display: block;
  }
  .grid02{
    display: block;

  }
  .gridPiatti{
    display: block;
  }
  .boxlist{
    display: block;
  }
  .cassa{
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    padding: 0;
    height: 60px;
    width: 100%;
  }
  .cassaMobile{
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    padding: 0;
    /*height: 150px;*/
    width: 100%;
    background: var(--color-primary);
    /*display: flex;*/
    display: block;
  }
  .gridSin{
    display: block;
  }
  .piatti div h2{
    margin-left: 10px
  }
  .inputD{
    display: block;
  }
  .inputD input{
    width: 87%;
  }
  .cardList{
    padding: 3px
  }
  .barraCategorie nav{
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  .boxList{

  }
  .mobileC{
    display: block;

  }
  .DescckC{
    display: none;
  }
  .cartCloseMob{
    display: flex;
    padding: 20px;
    padding-top:10px;

  }
  .modalType{
    width: 100%
  }
  .customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
  padding: 0px
}
.react-responsive-modal-overlay{
  padding: 0px
}
.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

}
